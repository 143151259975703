// SidebarContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [isSidebarHidden, setSidebarHidden] = useState(false);

  const toggleSidebar = () => {
    setSidebarHidden((prev) => !prev);
  };

  useEffect(() => {
    function handleResize() {
        if (window.innerWidth >= 768 && isSidebarHidden) {
            setSidebarHidden(false);
        } else if (window.innerWidth < 768 && !isSidebarHidden) {
            setSidebarHidden(true);
        }
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
}, [isSidebarHidden]);

  return (
    <SidebarContext.Provider value={{ isSidebarHidden, toggleSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
