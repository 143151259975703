import React from "react";
import { useSelector } from "react-redux";
const Welcome = () => {
  const { user } = useSelector((state) => state.auth);
  const apiUrl = process.env.REACT_APP_API_BASE_URL
  const titleStyle = {
    fontFamily: 'Times New Roman, Times, serif'
  };
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.5rem',
    backgroundColor: '#1a202c',
    minHeight: '100vh'
  };
  
  const cardStyle = (bgColor, textColor, gradientFrom, gradientTo) => ({
    padding: '1.25rem',
    background: `linear-gradient(to right, ${gradientFrom}, ${gradientTo})`,
    borderRadius: '0.5rem',
    position: 'relative',
    overflow: 'hidden',
    color: textColor
  });
  
  const svgStyle = {
    position: 'absolute',
    right: '0',
    bottom: '0',
    height: '8rem',
    width: '8rem',
    marginRight: '-2rem',
    marginBottom: '-2rem',
    opacity: '0.5'
  };
  // Example statistics for demonstration
  const statistics = [
    { label: "Total Users", value: 100 },
    { label: "Active Users", value: 80 },
    { label: "Inactive Users", value: 20 },
  ];

  return (
    <div>
      <h2 className="title">Dashboard</h2>
      <h2 className="subtitle" style={titleStyle}>
        Welcome Back <strong>{user && user.name}</strong>
      </h2>
      <ul className="box-info">

      <div style={cardStyle('#2dd4bf', '#e2e8f0', '#2dd4bf', '#16a34a')}>
          <div style={{ fontSize: '2.5rem', marginBottom: '1rem', fontWeight: '600' }}>$5000.00</div>
          <div style={{ color: '#d1fae5', fontWeight: '600' }}>Next month's income</div>
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" style={svgStyle}>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <div style={cardStyle('#2dd4bf', '#e2e8f0', '#2dd4bf', '#16a34a')}>
          <div style={{ fontSize: '2.5rem', marginBottom: '1rem', fontWeight: '600' }}>$5000.00</div>
          <div style={{ color: '#d1fae5', fontWeight: '600' }}>Next month's income</div>
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" style={svgStyle}>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        <div style={cardStyle('#2dd4bf', '#e2e8f0', '#2dd4bf', '#16a34a')}>
          <div style={{ fontSize: '2.5rem', marginBottom: '1rem', fontWeight: '600' }}>$5000.00</div>
          <div style={{ color: '#d1fae5', fontWeight: '600' }}>Next month's income</div>
          <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" style={svgStyle}>
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </div>
        </ul>

    </div>
  );
};

export default Welcome;
