// import React from 'react';
// import './home.css';

// function HomeSection() {
//   return (
//     <section className="home" id="home">
//       <div className="content">
//         <h3>the best courses you will find here</h3>
//         <p>Empower Your Future with Cutting-Edge Computer Training. Master New Skills and Excel in the Digital World!</p>
//         <a href="#courses" className="btn">More Courses</a>    
//       </div>
//     </section>
//   );
// }

// export default HomeSection;


import React from 'react';
import styles from './home.module.css'; // Importing the modular CSS file
const inlineStyle = {
  padding: '5rem 7%',
  border: '1px solid var(--primary-color)',
  borderRadius: '10px',
  marginBottom: '5px',
  marginLeft: '1px'
};
function HomeSection() {
  return (
    
    <section style={inlineStyle} className={`${styles.home}`} id="home">
      <div className={styles.content}>
        <h3>the best courses you will find here</h3>
        <p>Empower Your Future with Cutting-Edge Computer Training. Master New Skills and Excel in the Digital World!</p>
        <a href="#courses" className={`${styles['bttn']}`}>More Courses</a>    
      </div>
    </section>
  );
}

export default HomeSection;
