import React from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import '../styles/index.css'

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      {/* <section id='content'>
      <Navbar />
      </section>
      <div className="columns mt-6" style={{ minHeight: "100vh" }}>
        <div className="column is-2">
          <Sidebar />
        </div>
        <div className="column has-background-light">
          <main></main>
        </div>
      </div> */}

      <div>
      <Sidebar />
      <section id="content">
        <Navbar />
        <main>
        {children}
        </main>
        
      </section>
    </div>
    </React.Fragment>
  );
};

export default Layout;
